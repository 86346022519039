import React, { useState } from 'react';
import { TextField, Button, Typography, Box, Alert } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import {t} from "i18next";

// Function to generate CAPTCHA text
const generateCaptcha = () => {
    const characters = 'ABCDEFGHJKLMNPQRSTUVWXYZ23456789';
    let captcha = '';
    for (let i = 0; i < 6; i++) {
        captcha += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return captcha;
};

const Captcha = ({ onCaptchaValidate }) => {
    const [captchaText, setCaptchaText] = useState(generateCaptcha());
    const [userInput, setUserInput] = useState('');
    const [error, setError] = useState('');

    // Handle form submission to validate CAPTCHA
    const handleSubmit = (e) => {
        e.preventDefault();
        if (userInput === captchaText) {
            setError('');
            onCaptchaValidate(true); // Notify parent that CAPTCHA is valid
        } else {
            setError(t('passwordRecovery.captchaIncorrectError'));
            onCaptchaValidate(false); // Notify parent that CAPTCHA is invalid
        }
    };

    // Handle CAPTCHA refresh
    const handleRefreshCaptcha = () => {
        setCaptchaText(generateCaptcha());
        setUserInput('');
        setError('');
        onCaptchaValidate(false); // Reset CAPTCHA validity
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
            <Typography variant="h6">{t('passwordRecovery.pleaseCompleteCaptcha')}</Typography>

            {/* Display CAPTCHA text */}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '50px',
                    border: '1px solid #ccc',
                    backgroundColor: '#f5f5f5',
                    fontSize: '24px',
                    fontFamily: 'Arial, sans-serif',
                    textTransform: 'uppercase',
                }}
            >
                <Typography variant="h5">{captchaText}</Typography>
            </Box>

            {/* Input for CAPTCHA response */}
            <TextField
                label={t('passwordRecovery.enterCaptchaLabel')}
                variant="outlined"
                value={userInput}
                onChange={(e) => setUserInput(e.target.value)}
                fullWidth
                required
                error={!!error}
                helperText={error}
            />

            {/* Refresh CAPTCHA button */}
            <Button
                variant="outlined"
                color="primary"
                startIcon={<RefreshIcon />}
                onClick={handleRefreshCaptcha}
            >
                {t('passwordRecovery.captchaRefreshButton')} {/* Translated button text */}
            </Button>

            {/* Submit button */}
            <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                sx={{ marginTop: 2 }}
            >
                {t('passwordRecovery.submitButton')}
            </Button>
            <hr style={{minWidth: "30px"}}/>
        </Box>
    );
};

export default Captcha;

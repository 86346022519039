import React, { useState } from 'react';
import {
    Box,
    Card,
    CardContent,
    Typography,
    TextField,
    Button,
    CardActions,
    Alert,
    FormControlLabel
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Captcha from "../../Components/Captcha/Captcha";
import Checkbox from "@mui/material/Checkbox"; // Import i18n hook

const PasswordRecovery = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [user, setUser] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [captchaValid, setCaptchaValid] = useState(false); // Track CAPTCHA validation
    const [captchaChecked, setCaptchaChecked] = useState(false); // Track if the "Solve Captcha" checkbox is checked
    const navigate = useNavigate();  // Initialize navigate
    const { t } = useTranslation();  // Use the translation hook

    // Callback to update CAPTCHA validity
    const handleCaptchaValidation = (isValid) => {
        setCaptchaValid(isValid);
    };

    const handleCheckboxChange = (e) => {
        setCaptchaChecked(e.target.checked); // Update the state when checkbox is clicked
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        setMessage('');

        if (!captchaValid) {
            setError(t('passwordRecovery.captchaError')); // Error message for unvalidated CAPTCHA
            setLoading(false);
            return;
        }

        // Validate email input
        if (!email) {
            setError(t('passwordRecovery.emailRequired')); // Translated "Email is required"
            setLoading(false);
            return;
        }

        const body = { email };

        try {
            const response = await fetch('https://seequipment.pl/api/forgot-password', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            });

            const data = await response.json();

            if (response.ok) {
                setMessage(t(data.message) || t('passwordRecovery.successMessage'));

                // Redirect to the "Recovery Email Sent" page
                setTimeout(() => {
                    navigate('/recovery-email-sent');
                }, 2000); // 2-second delay before redirecting
            } else {
                setError(t(data.error) || t('passwordRecovery.errorMessage'));
            }
        } catch (error) {
            setError(t('passwordRecovery.networkError'));
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100vh"
            bgcolor="#f5f5f5"
        >
            <Card sx={{ minWidth: 275, maxWidth: 400 }}>
                <CardContent>
                    <Typography variant="h5" component="div" gutterBottom>
                        {t('passwordRecovery.title')} {/* Translated title */}
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <TextField
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            label={t('passwordRecovery.emailLabel')}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            required
                        />

                        {/* Solve Captcha Checkbox */}
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={captchaChecked}
                                    onChange={handleCheckboxChange}
                                    color="primary"
                                />
                            }
                            label={t('passwordRecovery.solveCaptchaLabel')} // Translate the checkbox label
                        />

                        {/* Only display CAPTCHA if the checkbox is checked */}
                        {captchaChecked && <Captcha onCaptchaValidate={handleCaptchaValidation} />}

                        <CardActions>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                fullWidth
                                disabled={loading || !captchaValid}
                            >
                                {loading ? t('passwordRecovery.sendingButton') : t('passwordRecovery.submitButton')} {/* Translated button text */}
                            </Button>
                        </CardActions>
                    </form>

                    {message && (
                        <Alert severity="success" sx={{ marginTop: 2 }}>
                            {message}
                        </Alert>
                    )}

                    {error && (
                        <Alert severity="error" sx={{ marginTop: 2 }}>
                            {error}
                        </Alert>
                    )}
                </CardContent>
            </Card>
        </Box>
    );
};

export default PasswordRecovery;
